import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import AIDevBenefits from '~/mock/services/ai-development/AIDevBenefits'
import AIDevBusiness from '~/mock/services/ai-development/AIDevBusiness'
import AIDevExpertise from '~/mock/services/ai-development/AIDevExpertise'
import AIDevFAQ from '~/mock/services/ai-development/AIDevFAQ'
import AIDevProcess from '~/mock/services/ai-development/AIDevProcess'
import AIDevServices from '~/mock/services/ai-development/AIDevServices'
import AIDevTrusted from '~/mock/services/ai-development/AIDevTrusted'
import Anchors from '~/mock/services/ai-development/Anchors'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import useAIDevelopmentStaticQuery from '~/views/Services/AIDevelopment/useAIDevelopmentStaticQuery'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesAIDevExpertiseSection from '~/views/Services/components/ServicesAIDevExpertiseSection'
import ServicesFullVideoSection from '~/views/Services/components/ServicesFullVideoSection'
import ServicesProcess from '~/views/Services/components/ServicesProcess'

import * as containerStyles from './AIDevelopment.module.scss'

const AIDevelopment = () => {
  const query = useAIDevelopmentStaticQuery()
  const clientIX = getImage(fileToImageLikeData(query.clientIX))
  const fullVideoImage = getImage(fileToImageLikeData(query.fullVideoImage))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.aiPrimeSection}>
        <PrimeSectionServices
          titleTop="AI Development"
          title="Services"
          description="Discover the power of AI to drive growth and efficiency. Our AI development services will help you stay on the innovation frontier and delight your customers."
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="AI Development Services"
          breadcrumbCurrentPath="/services/ai-development-services/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section className={containerStyles.aiServicesIconSection} id="services">
        <HeadSection
          position="center"
          title="AI development services we provide"
          description="Our expertise ensures that you obtain stable and reliable AI-driven tools. Using the latest development techniques and practices, we create AI solutions that help you thrive."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={AIDevServices} />
      </section>

      <section className={containerStyles.aiIndustriesSection} id="industries">
        <HeadSection
          position="center"
          title="Industries we implement"
          description="We understand the specific demands of various industries. With a solid commitment to industry-specific insights, our team offers cutting-edge AI services that drive innovation and transformation."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={AIDevExpertise} />
      </section>

      <CallToAction
        title="In search of an AI solution for your project?"
        subtitle="We are here to help."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.aiBenefitsSection} id="benefits">
        <HeadSection
          position="center"
          title="Take advantage of a reputable Artificial Intelligence development company"
          description="As a full-cycle artificial intelligence software development company, we have expertise in delivering solutions that bring substantial, quantifiable results. Our team will help you surpass the limits and strengthen your business with future-proof AI solutions."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={AIDevTrusted} />
      </section>

      <section className={containerStyles.aiPracticesSection} id="practices">
        <HeadSection
          position="center"
          title="Our personalized approach to AI application development services"
          description="At every step of working on an AI project, our specialists provide unique, customized solutions, considering your technical requirements and industry specifics."
          descriptionLarge
        />
        <ServicesAIDevExpertiseSection />
      </section>

      <section className={containerStyles.aiProcessSection} id="process">
        <HeadSection
          position="center"
          title="Bringing your idea to life: our AI development process"
          description="As an experienced AI application development company, we have a refined development process that ensures the best results. With our research and development approach, every team member contributes to the fast and quality delivery of your project."
          descriptionLarge
        />
        <ServicesProcess dataContent={AIDevProcess} />
      </section>

      <CallToAction
        title="Leveraging AI for your solution?"
        subtitle="We know how to implement it."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="ai-for-business"
        className={containerStyles.aiBusinessSection}
      >
        <HeadSection
          position="center"
          title="How will artificial intelligence development benefit your business?"
          description="AI solutions and integrations create a return on investment (ROI) from the start. With AI technologies, you gain precise data to understand your audiences and empower your app or website with automation and personalization."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isLeft dataContent={AIDevBusiness} />
      </section>

      <section className={containerStyles.aiCasesSection} id="cases">
        <HeadSection
          position="center"
          title="Our case studies"
          description="We are delighted to share our experience with you. See our portfolio to discover more about the projects we have delivered so far."
          descriptionLarge
        />
        <LazyHydrate whenVisible>
          <ServicesFullVideoSection
            videoLink="https://www.youtube.com/embed/A8Bd1aiwpRU"
            videoImage={fullVideoImage}
            videoTitle="Our case studies"
          />
        </LazyHydrate>
      </section>

      <section className={containerStyles.aiCompanySection} id="ai-with-codica">
        <HeadSection
          position="center"
          title="Benefits of developing your AI software & application with Codica"
          description="Hiring us will ensure that your AI solution will be enjoyable and stable. Our versatile expertise in Artificial Intelligence services and solutions will help you with timely project delivery per your requirements."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={AIDevBenefits} />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.aiProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.aiAwardsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are happy to share our accomplishments with you. The leading rating and review platforms have acknowledged our work for several years."
          descriptionLarge
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.aiQuoteSection} id="testimonials">
        <HeadSection
          position="center"
          title="Our happy clients say about Codica"
          description="We have helped our clients to reach their goals and open the opportunities they seek. Their testimonials are a precious source of inspiration to us."
          titleLarge
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Ben Alexander"
            location="Australia"
            position="Director, iExpedition"
            image={clientIX}
            quote={[
              {
                text: '“From the beginning, the whole process of development has been an absolute pleasure. The team operates on the highest professional level and communicates effectively. I have personally worked alongside Codica more than with the travel software company we had in Australia.”',
              },
            ]}
            link="travel-management-platform"
            videoLink="https://www.youtube.com/embed/njVC42-gThw"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s start your AI project journey now!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom software development experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={AIDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default AIDevelopment
